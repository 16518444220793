/*
Blue and Green Theme
*/

// Main
$main-background: #0d1017;
$main-text-color: #fff;

// Buttons and Links
$button-background: #86c1c9;
$button-color: #f1fffa;
$button-hover-background: #a296f7;
$button-active-background: $button-background;
$button-hover-background-2: #a296f7;

// Navbar
$navbar-background: #1b202c;

// Accents
$accent-1: #76f5e8;

// Dark Cards
$dark-card-background: #232325;

// TODO box-shadow: 0 4px 8px 0 rgb(236 236 236 / 20%), 0 6px 20px 0 rgb(190 190 190 / 19%);

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $main-background;
  color: $main-text-color;
}
