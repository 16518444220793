@import "../../App.scss";
.ModelRenderer {
    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;


    .fullscreen-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
        z-index: 1;
        border-radius: 4px;
        padding: 4px;
        background-color: $main-background;
        color: $accent-1;
        cursor: pointer;
    }
    .fullscreen-button:hover {
        background-color: $accent-1;
        color: $main-background;
    }

    .load-model-button{
        margin: 10px auto;
        z-index: 1;
        border-radius: 4px;
        padding: 4px;
        width: 50%;
        background-color: $main-background;
        color: $accent-1;
        cursor: pointer;
    }
    .load-model-button:hover {
        background-color: $accent-1;
        color: $main-background;
    }
}
