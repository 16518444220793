@import "../../App.scss";
.ToolTip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    background-color: $main-background;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    // Always on top
    z-index: 1;

    
}
