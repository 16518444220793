@import "../../App.scss";
.InfoCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .InfoCard__through{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .InfoCard__through__list{
            padding: 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            justify-items: center;

        }
        li{
            list-style: none;
            margin: 0 10px;
            padding: 10px;
            background-color: $main-background;
            color: $accent-1;
            border-radius: 5px;
            // Have text in single line
            white-space: nowrap;
        }
        
    }


    .InfoCard__skills{
        // Centering the list
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .InfoCard__skills__list{
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        li{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $main-background;
            color: $accent-1;
            border-radius: 5px;
            // Have text in single line
            white-space: nowrap;
        }
        li:hover{
            background-color: $accent-1;
            color: $main-background;
            cursor: pointer;
        }
        li:active{
            background-color: $accent-1;
            color: $main-background;
        }
        
        

    }

}
