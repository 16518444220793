@import "../../App.scss";
// General
.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .card {
    background-color: $dark-card-background;
    border: 1px solid $accent-1;
    padding: 20px;
    border-radius: 8px;
    display:flex;
    flex-direction: column;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .card-content {
    font-size: 1rem;
  }
