// import scss
@import "../../App.scss";

.NavigationBar {
  // Will always be on top of the page
  position: fixed;
  top: 0;
  left: 0;
  padding: 2px 0px;
  width: 100%;
  height: 60px;
  background-color: $navbar-background;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-link {
    background-color: $button-background;
    padding: 10px;
    border-radius: 5px;
    margin: 0 10px;
    text-decoration: none;
    color: $button-color;
  }

  .active-link {
    background-color: rgb(150, 140, 255);
  }

  // For mobile only use active
  @media only screen and (max-width: 600px) {
    .nav-link:active {
      background-color: $button-hover-background;
    }
  }
  // For desktop only use hover
  @media only screen and (min-width: 600px) {
    .nav-link:hover {
      background-color: $button-hover-background;
    }
  }
}

.Content {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 20px;
  height: 82.4vh; // TODO fix this
  overflow-y: auto;
}
