@import "../../App.scss";
.Footer {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color:$navbar-background;
    padding: 10px 0px;
    text-align: center;
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
}


.social-links a{
    color: $main-text-color;
    text-decoration: none;
    transition:color 0.3s ease;
}

.social-links a:hover{
    color: $button-hover-background-2;
}